@import '../../../../styles/variables';

.cta {
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
	text-align: center;
}

.phoneButton {
	margin-top: 12px;
	padding: 6px 15px;

	font-family: Inter, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 140%;

	border-radius: 30px;
	color: #596680;
	background: $color-btn-disabled;
}

.submitBtn {
	margin-top: 24px;
	display: block;
}

.input {
	margin-top: 18px;
}

.ofertaLink {
	color: #2B5DA8;
}

.ofertaCheckbox {
	margin-top: 15px;
}
