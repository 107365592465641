.root {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		content: '';
		display: block;
		width: 48px;
		height: 48px;
		border: 5px solid #004F89;
		border-bottom-color: transparent;
		border-radius: 50%;
		animation: rotation 1s linear infinite;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
