.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.value {
	margin: 0 6px;
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 130%;
	color: white;

	&.value_black {
		color: black;
	}
}

.btn {
	flex-shrink: 0;
}
