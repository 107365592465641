@import '../../../../../../../styles/variables';

.root {
	padding: 10.5px 0;
	width: 36px;

	border-bottom: 2px solid #D5D9E2;

	color: $color-btn-text;

	font-family: Inter, sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	text-align: center;

	&:focus {
		border-color: #2B5DA8;
	}
}

.error {
	border-color: #FF3B30;
}
