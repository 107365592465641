* {
	box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	font-family: Inter, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: darkgray;
}

html, body, #root {
	min-height: 100vh;
}

#root {
	height: 1px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

ul, ol {
	padding: 0;
	margin: 0;
  list-style: none;
}

p {
	margin: 0;
}

a {
	font-family: inherit;
  text-decoration: none;
}

input {
	padding: 0;
	border-radius: 0; // reset iOS
	box-shadow: none;
	outline: none;
	border: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

button {
	font-family: inherit;
  padding: 0;
	border: none;
	background: transparent;
	outline: none;
  line-height: normal;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  touch-action: manipulation;
}

.visually-hidden {
	position: absolute;

	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;

	white-space: nowrap;

	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}
