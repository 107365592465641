@import '../../../../styles/variables';

.root {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background-color: $color-btn-active;

	&:active {
		background-color: #D6910A;
	}

	&:disabled {
		background-color: $color-btn-active;
		opacity: 0.6;
		cursor: default;
	}

	&.colorBlue {
		background-color: #2B5DA8;

		&:active {
			background-color: #01339A;
		}

		&:disabled {
			background-color: #2B5DA8;
		}
	}
}

.minus {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23000' d='M9 18.5V17h18v1.5H9Z'/%3E%3C/svg%3E%0A");

	&.colorBlue {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23FFF' d='M9 18.5V17h18v1.5H9Z'/%3E%3C/svg%3E%0A");
	}
}

.plus {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23000' d='M18.75 8.75h-1.5V17H9v1.5h8.25v8.25h1.5V18.5H27V17h-8.25V8.75Z'/%3E%3C/svg%3E%0A");

	&.colorBlue {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23FFF' d='M18.75 8.75h-1.5V17H9v1.5h8.25v8.25h1.5V18.5H27V17h-8.25V8.75Z'/%3E%3C/svg%3E%0A");
	}
}
