$teleBingoBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #055EB1 0%, #003399 44.85%);
$kenBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #2CD0DD 0%, #5199F1 44.85%);
$sevenBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #7DBF2A 0%, #439222 44.85%);
$sixBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #FFD004 0%, #FFB800 44.85%);
$kenSecondBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #01659A 0%, #004F89 44.85%);
$fourthBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #FF4C4C 0%, #E83129 44.85%);
$eighthBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #D049F9 0%, #800CA9 44.85%);
$fifthBackground: radial-gradient(165.63% 70.91% at 17.68% 10.38%, #055EB1 0%, #003399 44.85%);
$buyButton: #FFCD17;
$color-btn: #FFCD17;
$color-btn-active: #E5A830;
$color-btn-text: #1C1C1E;
$color-btn-disabled: #E7EAEF;
$amountButton: #E5A830;
$font: 'Inter-Regular', sans-serif;
$grey1:  #404040;
$grey2:  #8693AB;
