.root {
	padding-left: 33px;
	position: relative;
	min-height: 18px;
	display: inline-block;

	font-size: 14px;
	font-weight: 500;
	line-height: 130%;
	letter-spacing: 0.1px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 16px;
		height: 16px;
		border-radius: 2px;
		border: 1px solid #2B5DA8;
		background-position: center;
	}

	&.checked::before {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23fff' d='m15.5 6-1.4-1.4-6.6 6.6-2.6-2.6L3.5 10l4 4 8-8Z'/%3E%3C/svg%3E");
		background-color: #2B5DA8;
	}
}

.label {
	font-size: 14px;
	font-weight: 500;
	line-height: 130%;
	letter-spacing: 0.1px;
}
