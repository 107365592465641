@import '../../../../styles/variables';

.root {
	padding: 10.5px 10.5px 9.5px;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	font-family: Inter, sans-serif;
	font-size: 16px;
	line-height: 130%;
	text-align: center;

	border-radius: 25px;
	color: $color-btn-text;
	background-color: $color-btn;

	&:active {
		background-color: $color-btn-active;
	}

	&.blue {
		color: white;
		background-color: #2B5DA8;

		&:active {
			background-color: #01339A;
		}
	}
}

.buy {
	font-weight: 600;
}

.price {
	font-weight: 700;

	&::before {
		content: '|';
		margin-left: 4px;
		margin-right: 4px;
		color: #D6910A;
		font-size: 14px;
		font-weight: 400;
		line-height: 130%;

		.blue & {
			color: white;
		}
	}
}
