.root {
	text-align: center;
}

.input {
	margin-top: 16px;
}

.cta {
	margin-top: 16px;

	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
	text-align: center;
}

.retry {
	margin-top: 50px;
	padding-top: 7px;
	padding-bottom: 7px;

	display: inline-block;

	font-family: Inter, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.1px;

	color: #2B5DA8;

	&:disabled {
		color: #A7AEB9;
	}
}

.after {
	color: #A7AEB9;

	font-family: Inter, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.1px;
}
