@import '../../styles/variables';

.root {
	padding: 12px;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	font-family: Inter, sans-serif;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	font-weight: 500;

	border: 1px solid $color-btn;
	border-radius: 25px;
	color: $color-btn-text;
	background-color: $color-btn;

	&:active {
		background-color: $color-btn-active;
	}

	&:disabled {
		color: #A7AEB9;
		background-color: $color-btn-disabled;
		border-color: $color-btn-disabled;
	}

	svg {
		margin-right: 4px;

		display: block;
		flex-shrink: 0;
		width: 24px;
		height: 24px;
	}

	&.white {
		background-color: white;
		border-color: #D5D9E2;

		&:active {
			background-color: $color-btn-active;
			border-color: $color-btn-active;
		}

		&:disabled {
			color: #A7AEB9;
			background-color: $color-btn-disabled;
			border-color: $color-btn-disabled;

			svg {
				path {
					stroke: #A7AEB9;
				}
			}
		}
	}
}

.fullwidth {
	width: 100%;
}
