.root {
	padding: 13px 48px;
	position: relative;
	min-height: 50px;
}

.title {
	font-family: Inter, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 135%;
	text-align: center;
}

.close {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.back {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
