@font-face {
	font-family: "Inter";
	src: local("Inter-Medium"),
	url("../static/fonts/Inter/Inter-Medium.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Inter";
	src: local("Inter-Bold"),
	url("../static/fonts/Inter/Inter-Bold.ttf") format("truetype");
	font-weight: 700;
}

@font-face {
	font-family: "Inter";
	src: local("Inter-SemiBold"),
	url("../static/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Inter";
	src: local("Inter-Regular"),
	url("../static/fonts/Inter/Inter-Regular.ttf") format("truetype");
	font-weight: 400;
}
