.root {

}

.gameCard {
	display: flex;
}

.pict {
	width: 60px;
	height: 60px;
	flex-shrink: 0;
}

.info {
	margin-left: 12px;

	font-family: Inter, sans-serif;
}

.gameName {
	font-size: 16px;
	font-weight: 500;
	line-height: 21px;

	color: #1C1C1E;
}

.purchaseDetails {
	margin-top: 4px;

	font-size: 16px;
	font-weight: 400;
	line-height: 135%;

	color: black;
}

.dash {
	font-size: 20px;
}

.purchaseDetailsPrice {
	font-size: 20px;
	font-weight: 700;
	line-height: 27px;
}

.paymentMethodsButtons {
	margin-top: 18px;
}

.btn {
	& + & {
		margin-top: 12px;
	}
}

.btnIcon {
	margin-right: 4px;
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-position: center;
}
