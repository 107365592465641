.layout {
	display: flex;
	flex-direction: column;
}

.paymentStatus {
	margin-top: auto;
	margin-bottom: auto;
}

.purchaseDetails {
	margin-top: 24px;
}

.buyMore {
	margin-top: 24px;
}
