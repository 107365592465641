.nav {
	margin-bottom: -8px;
	margin-right: -8px;
	display: flex;
	flex-wrap: wrap;
}

.title {
	margin-top: 12px;

	font-size: 17px;
	font-weight: 600;
	line-height: 120%;
}

.linkBtn {
	margin-bottom: 8px;
	margin-right: 8px;
	position: relative;
	padding: 5px 12px;

	font-family: inherit;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	text-align: center;

	color: white;
	border-radius: 20px;
	background: #2B5DA8;
	min-width: 98px;

	&:hover, &:focus, &:active {
		border-radius: 20px;
		background: #F4A914;
	}

	&.linkBtnFullWidth {
		flex-grow: 1;
	}

	&.linkBtnArrow {
		padding-right: 34px;

		&::after {
			content: '';
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			width: 18px;
			height: 18px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='white' d='M10.674 9 6.087 4.413a.937.937 0 1 1 1.326-1.326l5.25 5.25a.938.938 0 0 1 0 1.326l-5.25 5.25a.938.938 0 0 1-1.326-1.326L10.674 9Z'/%3E%3C/svg%3E");
		}
	}
}

.list {
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	max-width: 400px;
}

.card {
	margin-top: 15px;
}
