.root {
	color: black;
}

.titleContainer {
	&::before {
		content: '';
		display: block;
		margin: 0 auto;
		width: 128px;
		height: 128px;
		background-size: contain;
	}
}

.pending {
	&::before {
		background-image: url(../../static/img/payment_status/pending.png);
	}
}

.success {
	&::before {
		background-image: url(../../static/img/payment_status/success.png);
	}
}

.error {
	&::before {
		background-image: url(../../static/img/payment_status/error.png);
	}
}

.title {
	margin-top: 8px;

	font-size: 20px;
	font-weight: 700;
	line-height: 27px;
	text-align: center;
}

.description {
	margin-top: 8px;

	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	text-align: center;
}
