$color-text: #8693AB;

.root {
	position: relative;
	padding: 4.5px 12px 3.5px;
	display: flex;
	flex-direction: column;
}

.label {
	order: -1;
	color: $color-text;
	font-family: Inter, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.1px;

	&::before {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 10px;
		border: 1px solid #D5D9E2;
	}
}

.input {
	padding: 0;

	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;

	color: black;
	outline: none;
	border: none;

	&::placeholder {
		font-family: inherit;
		color: $color-text;
	}

	&:focus + .label::before {
		border-color: $color-text;
	}
}

.clear {
	position: absolute;
	right: 7px;
	top: 50%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Ccircle cx='12' cy='12' r='11' fill='%231C1C1E' fill-opacity='.1'/%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M14.646 8.353a.5.5 0 0 1 .707.708l-2.792 2.793 2.864 2.863a.5.5 0 1 1-.708.707l-2.864-2.863-2.863 2.864a.5.5 0 1 1-.708-.708l2.864-2.864-2.793-2.792a.5.5 0 1 1 .708-.708l2.793 2.793 2.792-2.793Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
